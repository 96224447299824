//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Message from './Message';

export default {
    name: 'T',
    components: {
        Message,
    },
    props: {
        t: {
            type: String,
            required: false,
            default: '',
        },
        tc: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            isEditModalOpen: false,
        };
    },
    methods: {
        handleClick() {
            this.isEditModalOpen = true;
        },
    },
};
