import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/hu'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(dayOfYear)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(quarterOfYear)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isLeapYear)
dayjs.extend(isoWeek)
dayjs.extend(isoWeeksInYear)

dayjs.locale('en')

dayjs.tz.setDefault('Europe/Budapest')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
