export default {
    data() {
        return {
            DAY_OFF_TYPES: [1, 2, 3, 4, 5, 6, 7],
            APPROVABLE_DAY_OFF_TYPES: [1, 4, 5, 6],
        };
    },
    methods: {
        getDayOffTypeDetails(typeID) {
            const details = {
                label: '',
                emoji: '',
            };
            switch (typeID) {
                case 1:
                    details.label = this.$t('day_off.types.regular');
                    details.emoji = require('~/assets/images/emojis/beach-with-umbrella.png');
                    break;
                case 2:
                    details.label = this.$t('day_off.types.sick_leave');
                    details.emoji = require('~/assets/images/emojis/face-with-thermometer.png');

                    break;
                case 3:
                    details.label = this.$t('day_off.types.home_office');
                    details.emoji = require('~/assets/images/emojis/house.png');

                    break;
                case 4:
                    details.label = this.$t('day_off.types.maternity_leave');
                    details.emoji = require('~/assets/images/emojis/breast-feeding.png');

                    break;
                case 5:
                    details.label = this.$t('day_off.types.unpaid_leave');
                    details.emoji = require('~/assets/images/emojis/umbrella-on-ground.png');

                    break;
                case 6:
                    details.label = this.$t('day_off.types.bereavement_leave');
                    details.emoji = require('~/assets/images/emojis/latin-cross.png');

                    break;
                case 7:
                    details.label = this.$t('day_off.types.carried_over_extra');
                    details.emoji = require('~/assets/images/emojis/beach-with-umbrella.png');

                    break;
                case 8:
                    details.label = this.$t('day_off.type.8');
                    details.emoji = require('~/assets/images/emojis/beach-with-umbrella.png');

                    break;
                default:
                    details.label = this.$t('day_off.types.regular');
                    details.emoji = require('~/assets/images/emojis/beach-with-umbrella.png');
            }

            return details;
        },
        getDayOffTypes(modalId, userID) {
            const dayOffTypes =
                (userID && +userID === this.$user.id) || this.$store.state.auth?.user?.role.id === 1
                    ? this.$store.state.company.dayOffTypes.available
                    : this.$store.state.company.dayOffTypes.all;

            return Object.keys(dayOffTypes).map((typeId) => {
                return { id: +typeId, key: `${modalId}-${+typeId}`, label: dayOffTypes[typeId] };
            });
        },
        getNotificationContent(notification) {
            let user;
            const summary = {};
            const detail = {};

            // stuff that appears for APPROVERS if someone REQUESTED a day-off
            if (this.DAY_OFF_TYPES.includes(notification.type)) {
                user = this.$store.state.company.employeesEssential.find((user) => {
                    return (
                        user.id ===
                        (notification.data && notification.data.user_id
                            ? notification.data.user_id
                            : notification.user_id)
                    );
                });

                summary.message = `${user?.first_name} ${user?.last_name}`;
                summary.icon = user?.avatar;
                detail.icon = this.getDayOffTypeDetails(notification.type).emoji;
                detail.requestID = notification.data.model.id;
                detail.date = `${this.$t('day_off.on')} ${this.getFormattedDateInterval(
                    notification.data.model.from_date,
                    notification.data.model.to_date
                )}`;
            }

            // type 1: Someone made a day off request (regular day-off)
            if (notification.type === 1) {
                summary.message_aux = this.$t('day_off.notification.regular');
            }

            // type 2: Someone made a day off request (sick day-off)
            if (notification.type === 2) {
                summary.message_aux = this.$t('day_off.notification.sick');
            }

            // type 3: Someone will be on home office
            if (notification.type === 3) {
                summary.message_aux = this.$t('day_off.notification.home_office');
            }

            // type 4: Someone requested maternity leave
            if (notification.type === 4) {
                summary.message_aux = this.$t('day_off.notification.maternity_leave');
            }

            // type 5: Someone requested unpaid leave
            if (notification.type === 5) {
                summary.message_aux = this.$t('day_off.notification.unpaid_leave');
            }

            // type 6: Someone requested bereavement leave
            if (notification.type === 6) {
                summary.message_aux = this.$t('day_off.notification.bereavement_leave');
            }

            // type 7: carried over day off type
            // todo: ezzel mi legyen? ez nem requestelhető, sztem nem is lesz ilyen
            if (notification.type === 7) {
                summary.message_aux = 'Carried over day off';
            }

            // type 8: custom notification
            if (notification.type === 8) {
                // todo: custom notit lekezelni, kiszedni belőle a szöveget
                summary.message = 'custom notification';
            }

            // stuff that appears for you if you are who REQUESTED the day off
            // type 9: day-off request approved
            if (notification.type === 9) {
                const dayOffType = this.getDayOffTypeDetails(notification.data.model.type_id);

                if (this.$i18n.locale === 'hu') {
                    summary.message = `${dayOffType.label} igényed el lett fogadva`;
                } else {
                    summary.message = `Your request for ${dayOffType.label} has been approved`;
                }

                summary.icon = require('~/assets/images/icons/accepted.svg');
                detail.icon = dayOffType.emoji;
                detail.requestID = notification.data.model.id;
                detail.date = `${this.$t('request.on')} ${this.getFormattedDateInterval(
                    notification.data.model.from_date,
                    notification.data.model.to_date
                )}`;
            }

            // type 10: day-off request denied
            if (notification.type === 10) {
                const dayOffType = this.getDayOffTypeDetails(notification.data.model.type_id);

                if (this.$i18n.locale === 'hu') {
                    summary.message = `${dayOffType.label} igényed el lett utasítva`;
                } else {
                    summary.message = `Your request for ${dayOffType.label} has been denied`;
                }

                summary.icon = require('~/assets/images/icons/denied.svg');
                detail.icon = dayOffType.emoji;
                detail.requestID = notification.data.model.id;
                detail.date = `${this.$t('request.on')} ${this.getFormattedDateInterval(
                    notification.data.model.from_date,
                    notification.data.model.to_date
                )}`;
            }

            // 11 and 12 were workiversary and birthday that were taken off

            // type 13: day-off request denied
            if (notification.type === 13) {
                const dayOffType = this.getDayOffTypeDetails(notification.data.model.type_id);

                if (this.$i18n.locale === 'hu') {
                    summary.message = `${dayOffType.label} igényed módosítva lett`;
                } else {
                    summary.message = `Your request for ${dayOffType.label} has been modified`;
                }
                // todo: a módosított szabinak egy másik ikont választani
                summary.icon = require('~/assets/images/icons/settings.svg');
                detail.icon = dayOffType.emoji;
                detail.requestID = notification.data.model.id;
                detail.date = `${this.$t('request.on')} ${this.getFormattedDateInterval(
                    notification.data.model.from_date,
                    notification.data.model.to_date
                )}`;
            }

            // type 14: an admin has assigned a day off for you
            if (notification.type === 14) {
                summary.message = this.$t('day_off.notification.assigned_mandatory');

                summary.icon = require('~/assets/images/icons/bell.svg');
                detail.icon = require('~/assets/images/emojis/beach-with-umbrella.png');
                detail.requestID = notification.data.model.id;
                detail.date = `${this.$t('request.on')} ${this.getFormattedDateInterval(
                    notification.data.model.from_date,
                    notification.data.model.to_date
                )}`;
            }

            return {
                summary,
                detail,
            };
        },
    },
};
