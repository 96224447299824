export default {
    head() {
        const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

        return {
            htmlAttrs: i18nHead.htmlAttrs,
            meta: [...i18nHead.meta],
            link: [...i18nHead.link],
        };
    },
    watch: {
        '$i18n.locale'(value) {
            this.$primevue.config.locale = this.$primevue.config.locales[value];
        },
    },
    mounted() {
        this.$primevue.config.locale = this.$primevue.config.locales[this.$i18n.locale];
    },
};
