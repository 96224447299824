export default async function ({ store }) {
    if (store.state.company.completedInitialFetchAfterAuthentication) {
        return;
    }

    await Promise.all([
        store.dispatch('company/fetchUnapprovedDayOffRequests'),
        store.dispatch('company/fetchDaysOff'),
        store.dispatch('company/fetchEmployeesEssential'),
        store.dispatch('company/fetchHolidays'),
        store.dispatch('company/fetchAbsents'),
        store.dispatch('company/fetchGroups'),
        store.dispatch('company/fetchUpcomingEvents'),
    ]);

    store.commit('company/setCompletedInitialFetchAfterAuthentication', true);
}
