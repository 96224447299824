//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { throttle } from 'throttle-debounce';
import CreatedByMelkweg from '../../Generic/CreatedByMelkweg';
import dayOffRequest from '../../../mixins/dayOffRequest';
import LoggedInLogo from '../../Logos/LoggedInLogo';
import MelkwegLogo from '../../Logos/MelkwegLogo';
import NavLink from '../../Generic/NavLink';
import NotificationDropdown from './NotificationDropdown';

export default {
    components: {
        LoggedInLogo,
        NavLink,
        NotificationDropdown,
        MelkwegLogo,
        CreatedByMelkweg,
    },
    mixins: [dayOffRequest],
    provide() {
        return {
            removeNotification: this.removeNotification,
        };
    },
    removeMenuHasTransitionTimeout: null,
    data() {
        return {
            notifications: [],
            areNotificationsSeen: false,
            isNotificationDropdownOpen: false,
            isUserDropdownOpen: false,
            isMobileMenuOpen: false,

            menuHasTransition: false,
            prevWindowWidth: null,
            resizeHandler: throttle(100, this.handleResize),

            notificationUpdater: null,
        };
    },
    computed: {
        isMyProfileActive() {
            return (
                this.getRouteBaseName() &&
                !!this.getRouteBaseName().match(/^(user-personal-information|user-approvers|user-account-settings)/)
            );
        },
    },
    watch: {
        '$route.path'() {
            this.closeUserDropdown();
            this.closeNotificationDropdown();
        },
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandler);

        this.notificationUpdater = setInterval(this.updateNotifications, 2000 * 1000);

        this.updateNotifications();
    },
    beforeDestroy() {
        this.$enableBodyScroll(this.$refs.mobileNavigationScrollContainer);
        clearInterval(this.notificationUpdater);

        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        updateNotifications() {
            this.$axios.$get('/notification').then((res) => {
                if (res.success === 1) {
                    if (res.data.length) {
                        // todo: remove deleted notifications from the notifications_shown array
                        this.notifications = res.data;
                        // decide whether to make a toast for a notification or not.
                        // 1. get notifications from cookie
                        let shownNotificationIDs = this.$cookies.get('notifications_shown');

                        if (!shownNotificationIDs) {
                            shownNotificationIDs = [];
                        }

                        // 2. create an array containing only the notifications not alerted about yet
                        const notificationsToShow = [...this.notifications].filter((n) => {
                            return !shownNotificationIDs.includes(n.id);
                        });

                        // 3. create toast. Only display a maximum of 3 toast messages - either 3 notifications, or
                        // show 2 notifications + "x more"
                        let toastCount = 0;

                        notificationsToShow.forEach((notification) => {
                            if (notification.is_seen) {
                                return;
                            }

                            shownNotificationIDs.push(notification.id);

                            this.$store.dispatch('company/fetchUnapprovedDayOffRequests');
                            this.$store.dispatch('company/fetchDaysOff');

                            if (notification.type === 10) {
                                this.$store.dispatch('auth/fetchUser');
                            }

                            toastCount++;

                            if (toastCount <= 2) {
                                this.$toast.add({
                                    ...this.getNotificationContent(notification),
                                    life: 5000,
                                });
                            }
                        });

                        if (toastCount > 2) {
                            this.$toast.add({
                                summary: {
                                    icon: require('~/assets/images/icons/bell.svg'),
                                    message: `+${toastCount - 2} ${this.$t('notifications.more_notifications')}`,
                                },
                                life: 6000,
                            });
                        }

                        // remove notifications from cookie if they have been seen or deleted
                        if (notificationsToShow.length) {
                            const updatedShownNotificationIDs = shownNotificationIDs.reduce((acc, curr) => {
                                if (this.notifications.find((n) => n.id === curr && !n.is_seen)) {
                                    acc.push(curr);
                                }
                                return acc;
                            }, []);

                            this.$cookies.set('notifications_shown', updatedShownNotificationIDs, {
                                path: '/',
                                maxAge: 60 * 60 * 24 * 30 * 3 * 365 * 2,
                            });
                        }
                    }
                }
            });
        },
        removeNotification(notificationID) {
            this.notifications = [...this.notifications].filter((x) => x.id !== notificationID);
        },
        toggleMobileMenu() {
            if (window.innerWidth < 1024) {
                this.isMobileMenuOpen = !this.isMobileMenuOpen;
                if (this.isMobileMenuOpen) {
                    clearTimeout(this.removeMenuHasTransitionTimeout);
                    this.menuHasTransition = true;
                    this.$disableBodyScroll(this.$refs.mobileNavigationScrollContainer);
                    this.closeNotificationDropdown();
                } else {
                    this.removeMenuHasTransitionTimeout = setTimeout(() => {
                        this.menuHasTransition = false;
                    }, 500);
                    this.$nextTick(() => {
                        if (this.$refs.mobileNavigationScrollContainer) {
                            this.$enableBodyScroll(this.$refs.mobileNavigationScrollContainer);
                        }
                    });
                }
            }
        },
        handleResize() {
            if (window.innerWidth >= 1024 && this.prevWindowWidth < 1024) {
                this.isMobileMenuOpen = false;
                this.menuHasTransition = false;
                this.$nextTick(() => {
                    this.$enableBodyScroll(this.$refs.mobileNavigationScrollContainer);
                });
            } else if (window.innerWidth < 1024 && this.prevWindowWidth > 1024) {
                this.closeUserDropdown();
            }
            this.prevWindowWidth = window.innerWidth;
        },
        openNotificationDropdown() {
            this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
            this.closeUserDropdown();
            this.$axios.$get('/notification/seen').then((res) => {
                if (res.success === 1) {
                    this.areNotificationsSeen = true;
                }
            });
        },
        openUserDropdown() {
            this.isUserDropdownOpen = !this.isUserDropdownOpen;
            this.closeNotificationDropdown();
        },
        closeNotificationDropdown() {
            this.isNotificationDropdownOpen = false;
        },
        closeUserDropdown() {
            this.isUserDropdownOpen = false;
        },
    },
};
