export const DashboardStatusRow = () => import('../../components/Dashboard/StatusRow.vue' /* webpackChunkName: "components/dashboard-status-row" */).then(c => wrapFunctional(c.default || c))
export const DashboardUnpopulatedSectionPlaceholder = () => import('../../components/Dashboard/UnpopulatedSectionPlaceholder.vue' /* webpackChunkName: "components/dashboard-unpopulated-section-placeholder" */).then(c => wrapFunctional(c.default || c))
export const DashboardUpcomingCelebrations = () => import('../../components/Dashboard/UpcomingCelebrations.vue' /* webpackChunkName: "components/dashboard-upcoming-celebrations" */).then(c => wrapFunctional(c.default || c))
export const FormsContactForm = () => import('../../components/Forms/ContactForm.vue' /* webpackChunkName: "components/forms-contact-form" */).then(c => wrapFunctional(c.default || c))
export const FormsUserDataForm = () => import('../../components/Forms/UserDataForm.vue' /* webpackChunkName: "components/forms-user-data-form" */).then(c => wrapFunctional(c.default || c))
export const FullCalendarTimeline = () => import('../../components/FullCalendars/FullCalendarTimeline.vue' /* webpackChunkName: "components/full-calendar-timeline" */).then(c => wrapFunctional(c.default || c))
export const FullCalendarTooltip = () => import('../../components/FullCalendars/FullCalendarTooltip.vue' /* webpackChunkName: "components/full-calendar-tooltip" */).then(c => wrapFunctional(c.default || c))
export const GenericAvatarAndName = () => import('../../components/Generic/AvatarAndName.vue' /* webpackChunkName: "components/generic-avatar-and-name" */).then(c => wrapFunctional(c.default || c))
export const GenericCreatedByMelkweg = () => import('../../components/Generic/CreatedByMelkweg.vue' /* webpackChunkName: "components/generic-created-by-melkweg" */).then(c => wrapFunctional(c.default || c))
export const GenericMobileDropdownBgOverlay = () => import('../../components/Generic/MobileDropdownBgOverlay.vue' /* webpackChunkName: "components/generic-mobile-dropdown-bg-overlay" */).then(c => wrapFunctional(c.default || c))
export const GenericNavLink = () => import('../../components/Generic/NavLink.vue' /* webpackChunkName: "components/generic-nav-link" */).then(c => wrapFunctional(c.default || c))
export const GenericTabNav = () => import('../../components/Generic/TabNav.vue' /* webpackChunkName: "components/generic-tab-nav" */).then(c => wrapFunctional(c.default || c))
export const GenericTabNavItem = () => import('../../components/Generic/TabNavItem.vue' /* webpackChunkName: "components/generic-tab-nav-item" */).then(c => wrapFunctional(c.default || c))
export const GenericToastMessage = () => import('../../components/Generic/ToastMessage.vue' /* webpackChunkName: "components/generic-toast-message" */).then(c => wrapFunctional(c.default || c))
export const GenericToastMessageContent = () => import('../../components/Generic/ToastMessageContent.vue' /* webpackChunkName: "components/generic-toast-message-content" */).then(c => wrapFunctional(c.default || c))
export const GenericTooltip = () => import('../../components/Generic/Tooltip.vue' /* webpackChunkName: "components/generic-tooltip" */).then(c => wrapFunctional(c.default || c))
export const CardsSubscriptionPlanCard = () => import('../../components/Cards/SubscriptionPlanCard.vue' /* webpackChunkName: "components/cards-subscription-plan-card" */).then(c => wrapFunctional(c.default || c))
export const CardsTestimonialCard = () => import('../../components/Cards/TestimonialCard.vue' /* webpackChunkName: "components/cards-testimonial-card" */).then(c => wrapFunctional(c.default || c))
export const DataTablesAccordionData = () => import('../../components/DataTables/AccordionData.vue' /* webpackChunkName: "components/data-tables-accordion-data" */).then(c => wrapFunctional(c.default || c))
export const DataTablesAdminApproverRow = () => import('../../components/DataTables/AdminApproverRow.vue' /* webpackChunkName: "components/data-tables-admin-approver-row" */).then(c => wrapFunctional(c.default || c))
export const DataTablesBaseDataTableRow = () => import('../../components/DataTables/BaseDataTableRow.vue' /* webpackChunkName: "components/data-tables-base-data-table-row" */).then(c => wrapFunctional(c.default || c))
export const DataTablesChildDataRow = () => import('../../components/DataTables/ChildDataRow.vue' /* webpackChunkName: "components/data-tables-child-data-row" */).then(c => wrapFunctional(c.default || c))
export const DataTableHeadCell = () => import('../../components/DataTables/DataTableHeadCell.vue' /* webpackChunkName: "components/data-table-head-cell" */).then(c => wrapFunctional(c.default || c))
export const DataTablesDayOffRequestRow = () => import('../../components/DataTables/DayOffRequestRow.vue' /* webpackChunkName: "components/data-tables-day-off-request-row" */).then(c => wrapFunctional(c.default || c))
export const DataTablesDayOffRow = () => import('../../components/DataTables/DayOffRow.vue' /* webpackChunkName: "components/data-tables-day-off-row" */).then(c => wrapFunctional(c.default || c))
export const DataTablesDesktopDayOffComment = () => import('../../components/DataTables/DesktopDayOffComment.vue' /* webpackChunkName: "components/data-tables-desktop-day-off-comment" */).then(c => wrapFunctional(c.default || c))
export const DataTablesEmployeeRow = () => import('../../components/DataTables/EmployeeRow.vue' /* webpackChunkName: "components/data-tables-employee-row" */).then(c => wrapFunctional(c.default || c))
export const DataTablesGroupRow = () => import('../../components/DataTables/GroupRow.vue' /* webpackChunkName: "components/data-tables-group-row" */).then(c => wrapFunctional(c.default || c))
export const DataTablesNonWorkingDayRow = () => import('../../components/DataTables/NonWorkingDayRow.vue' /* webpackChunkName: "components/data-tables-non-working-day-row" */).then(c => wrapFunctional(c.default || c))
export const DataTablesTableData = () => import('../../components/DataTables/TableData.vue' /* webpackChunkName: "components/data-tables-table-data" */).then(c => wrapFunctional(c.default || c))
export const DataTablesTeamMemberDataRow = () => import('../../components/DataTables/TeamMemberDataRow.vue' /* webpackChunkName: "components/data-tables-team-member-data-row" */).then(c => wrapFunctional(c.default || c))
export const CarouselsBrandsCarousel = () => import('../../components/Carousels/BrandsCarousel.vue' /* webpackChunkName: "components/carousels-brands-carousel" */).then(c => wrapFunctional(c.default || c))
export const CarouselsTestimonialCarousel = () => import('../../components/Carousels/TestimonialCarousel.vue' /* webpackChunkName: "components/carousels-testimonial-carousel" */).then(c => wrapFunctional(c.default || c))
export const LayoutGridOverlay = () => import('../../components/Layout/GridOverlay.vue' /* webpackChunkName: "components/layout-grid-overlay" */).then(c => wrapFunctional(c.default || c))
export const LogosLoggedInLogo = () => import('../../components/Logos/LoggedInLogo.vue' /* webpackChunkName: "components/logos-logged-in-logo" */).then(c => wrapFunctional(c.default || c))
export const LogosLoggedOutLogo = () => import('../../components/Logos/LoggedOutLogo.vue' /* webpackChunkName: "components/logos-logged-out-logo" */).then(c => wrapFunctional(c.default || c))
export const LogosMelkwegLogo = () => import('../../components/Logos/MelkwegLogo.vue' /* webpackChunkName: "components/logos-melkweg-logo" */).then(c => wrapFunctional(c.default || c))
export const ModalsBaseModal = () => import('../../components/Modals/BaseModal.vue' /* webpackChunkName: "components/modals-base-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDayOffRequestModal = () => import('../../components/Modals/DayOffRequestModal.vue' /* webpackChunkName: "components/modals-day-off-request-modal" */).then(c => wrapFunctional(c.default || c))
export const GenericDaysOffCalculatorCustomEntries = () => import('../../components/Generic/DaysOffCalculator/CustomEntries.vue' /* webpackChunkName: "components/generic-days-off-calculator-custom-entries" */).then(c => wrapFunctional(c.default || c))
export const GenericDaysOffCalculator = () => import('../../components/Generic/DaysOffCalculator/DaysOffCalculator.vue' /* webpackChunkName: "components/generic-days-off-calculator" */).then(c => wrapFunctional(c.default || c))
export const LayoutFootersLoggedInFooter = () => import('../../components/Layout/Footers/LoggedInFooter.vue' /* webpackChunkName: "components/layout-footers-logged-in-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutFootersLoggedOutFooter = () => import('../../components/Layout/Footers/LoggedOutFooter.vue' /* webpackChunkName: "components/layout-footers-logged-out-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeadersDefaultHeader = () => import('../../components/Layout/Headers/DefaultHeader.vue' /* webpackChunkName: "components/layout-headers-default-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeadersLoggedInHeader = () => import('../../components/Layout/Headers/LoggedInHeader.vue' /* webpackChunkName: "components/layout-headers-logged-in-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeadersNotificationDropdown = () => import('../../components/Layout/Headers/NotificationDropdown.vue' /* webpackChunkName: "components/layout-headers-notification-dropdown" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeadersNotificationDropdownItem = () => import('../../components/Layout/Headers/NotificationDropdownItem.vue' /* webpackChunkName: "components/layout-headers-notification-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeadersSecondaryHeader = () => import('../../components/Layout/Headers/SecondaryHeader.vue' /* webpackChunkName: "components/layout-headers-secondary-header" */).then(c => wrapFunctional(c.default || c))
export const PagesCompanyAndUserExport = () => import('../../components/Pages/CompanyAndUser/Export.vue' /* webpackChunkName: "components/pages-company-and-user-export" */).then(c => wrapFunctional(c.default || c))
export const PagesHomeFeatureGridItem = () => import('../../components/Pages/Home/FeatureGridItem.vue' /* webpackChunkName: "components/pages-home-feature-grid-item" */).then(c => wrapFunctional(c.default || c))
export const PagesHomeHero = () => import('../../components/Pages/Home/Hero.vue' /* webpackChunkName: "components/pages-home-hero" */).then(c => wrapFunctional(c.default || c))
export const PagesHomeHowDoesItWorkVideo = () => import('../../components/Pages/Home/HowDoesItWorkVideo.vue' /* webpackChunkName: "components/pages-home-how-does-it-work-video" */).then(c => wrapFunctional(c.default || c))
export const PagesHomeManageYourTeamsHolidayBlock = () => import('../../components/Pages/Home/ManageYourTeamsHolidayBlock.vue' /* webpackChunkName: "components/pages-home-manage-your-teams-holiday-block" */).then(c => wrapFunctional(c.default || c))
export const PagesHomeMelkwegBanner = () => import('../../components/Pages/Home/MelkwegBanner.vue' /* webpackChunkName: "components/pages-home-melkweg-banner" */).then(c => wrapFunctional(c.default || c))
export const PagesHomePricing = () => import('../../components/Pages/Home/Pricing.vue' /* webpackChunkName: "components/pages-home-pricing" */).then(c => wrapFunctional(c.default || c))
export const PagesHomeProductFeatureBlock = () => import('../../components/Pages/Home/ProductFeatureBlock.vue' /* webpackChunkName: "components/pages-home-product-feature-block" */).then(c => wrapFunctional(c.default || c))
export const PagesHomeSubscriptionPlan = () => import('../../components/Pages/Home/SubscriptionPlan.vue' /* webpackChunkName: "components/pages-home-subscription-plan" */).then(c => wrapFunctional(c.default || c))
export const PagesRegisterProgressBar = () => import('../../components/Pages/Register/ProgressBar.vue' /* webpackChunkName: "components/pages-register-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const PagesRegisterStepper = () => import('../../components/Pages/Register/Stepper.vue' /* webpackChunkName: "components/pages-register-stepper" */).then(c => wrapFunctional(c.default || c))
export const PagesRegisterStepperNavItem = () => import('../../components/Pages/Register/StepperNavItem.vue' /* webpackChunkName: "components/pages-register-stepper-nav-item" */).then(c => wrapFunctional(c.default || c))
export const PagesUserAccountSettings = () => import('../../components/Pages/User/AccountSettings.vue' /* webpackChunkName: "components/pages-user-account-settings" */).then(c => wrapFunctional(c.default || c))
export const PagesUserAddNewTeamMember = () => import('../../components/Pages/User/AddNewTeamMember.vue' /* webpackChunkName: "components/pages-user-add-new-team-member" */).then(c => wrapFunctional(c.default || c))
export const PagesUserApprovers = () => import('../../components/Pages/User/Approvers.vue' /* webpackChunkName: "components/pages-user-approvers" */).then(c => wrapFunctional(c.default || c))
export const PagesUserDaysOff = () => import('../../components/Pages/User/DaysOff.vue' /* webpackChunkName: "components/pages-user-days-off" */).then(c => wrapFunctional(c.default || c))
export const PagesUserPersonalInformation = () => import('../../components/Pages/User/PersonalInformation.vue' /* webpackChunkName: "components/pages-user-personal-information" */).then(c => wrapFunctional(c.default || c))
export const UIInputsAvatarUpload = () => import('../../components/UI/Inputs/AvatarUpload.vue' /* webpackChunkName: "components/u-i-inputs-avatar-upload" */).then(c => wrapFunctional(c.default || c))
export const UIInputsCustomDatepicker = () => import('../../components/UI/Inputs/CustomDatepicker.vue' /* webpackChunkName: "components/u-i-inputs-custom-datepicker" */).then(c => wrapFunctional(c.default || c))
export const UIInputsExactPeriodDatepicker = () => import('../../components/UI/Inputs/ExactPeriodDatepicker.vue' /* webpackChunkName: "components/u-i-inputs-exact-period-datepicker" */).then(c => wrapFunctional(c.default || c))
export const UIInputsInputError = () => import('../../components/UI/Inputs/InputError.vue' /* webpackChunkName: "components/u-i-inputs-input-error" */).then(c => wrapFunctional(c.default || c))
export const UIInputsMonthPickerSelectDropdown = () => import('../../components/UI/Inputs/MonthPickerSelectDropdown.vue' /* webpackChunkName: "components/u-i-inputs-month-picker-select-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UIInputsStrengthMeterPassword = () => import('../../components/UI/Inputs/StrengthMeterPassword.vue' /* webpackChunkName: "components/u-i-inputs-strength-meter-password" */).then(c => wrapFunctional(c.default || c))
export const UITMessage = () => import('../../components/UI/T/Message.vue' /* webpackChunkName: "components/u-i-t-message" */).then(c => wrapFunctional(c.default || c))
export const UIT = () => import('../../components/UI/T/T.vue' /* webpackChunkName: "components/u-i-t" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
