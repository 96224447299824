// Some important words about ENVIRONMENTS and the ACCESS TOKEN
//
// On staging, the access token's expiry is 2 hours. On production, it is 5 minutes.
// This is because:
// 1. during development, your dev server (so your localhost) uses the staging backend.
// 2. The staging backend sends its cookies to the client with the domain attribute set to holi-frontend-staging.melkweg.hu.
// 3. The refresh-token cookie is no different, its domain is holi-frontend-staging.melkweg.hu too.
// 4. When you call the /auth/refresh-token api, the browser won't pass cookies with
// the domain attribute different to your dev server's ip to the nuxt server. So the backend won't be getting your
// refresh token cookie. Cookie will be passed to the request header when you call the api from the browser, but
// not from the development server.
// 5. That said, our only hope is to have a fairly long-lived access token in order to not
// be logged from our account during development every ~5 minutes.

export default function ({ $axios, app, i18n, $config, store, $cookies }) {
    $axios.interceptors.request.use(async (request) => {
        request.meta = request.meta || {};
        request.meta.requestStartedAt = new Date().getTime();

        if (!request.url.match(/\/(auth|landing|contact|save-invite-password|avatar\.upload)/)) {
            await store.dispatch('auth/refreshToken', request.url);
        }

        request.headers.Authorization = store.state.auth.token ? 'Bearer ' + store.state.auth.token : '';

        return request;
    });

    // $axios.interceptors.response.use((response) => {
    //     console.log(`finished ${response.config.url} ${response.config.method.toUpperCase()}`);
    //
    //     return response;
    // });

    $axios.onError((error) => {
        console.log('response error on ' + error?.config?.url);
        console.log(error?.response?.status + ' (' + error?.response?.statusText + ')');
    });

    $axios.defaults.withCredentials = true;
    $axios.defaults.baseURL = $config.apiBaseURL + '/api/' + i18n.locale;
}
