//
//
//
//
//
//
//
//

export default {
    name: 'InputError',
    props: {
        errors: {
            type: Array,
            required: true,
        },
    },
};
