//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationItem from './NotificationDropdownItem';

export default {
    name: 'NotificationDropdown',
    components: {
        NotificationItem,
    },
    props: {
        notifications: {
            type: Array,
            required: true,
        },
    },
};
