import { render, staticRenderFns } from "./DefaultHeader.vue?vue&type=template&id=c3ed8bbc&scoped=true&"
import script from "./DefaultHeader.vue?vue&type=script&lang=js&"
export * from "./DefaultHeader.vue?vue&type=script&lang=js&"
import style0 from "./DefaultHeader.vue?vue&type=style&index=0&lang=css&"
import style1 from "./DefaultHeader.vue?vue&type=style&index=1&id=c3ed8bbc&lang=postcss&scoped=true&"
import style2 from "./DefaultHeader.vue?vue&type=style&index=2&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3ed8bbc",
  null
  
)

export default component.exports