// import { disableBodyScroll, enableBodyScroll } from '../static/scripts/body-scroll-lock.min';

export default ({ app, store, toast, $device, $dayjs }, inject) => {
    // inject('disableBodyScroll', disableBodyScroll);
    // inject('enableBodyScroll', enableBodyScroll);
    inject('scrollToElement', (selector, offset = 150, mode = 'smooth') => {
        const targetScrollY = document.querySelector(selector).getBoundingClientRect().top + window.scrollY - offset;

        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ left: 0, top: targetScrollY, behavior: mode });
        } else {
            window.scrollTo(0, targetScrollY);
        }
    });
    inject('downloadFile', (url, fileName) => {
        fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileName);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });
    });
    inject('clone', (item) => {
        return JSON.parse(JSON.stringify(item));
    });
    inject('preventContextMenu', (event) => {
        if ($device.isDesktop) {
            return;
        }

        event.preventDefault();
        event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
        event.stopImmediatePropagation();
        return false;
    });
    inject('getWeekDays', () => {
        return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    });
    inject('getDurationInDays', (startDate, endDate) => {
        const start = $dayjs(startDate, 'YYYY-MM-DD');
        const end = $dayjs(endDate, 'YYYY-MM-DD');
        return end.add(1, 'day').diff(start, 'day');
    });
    inject('getISODate', (val = '') => {
        // expect a string or a date object
        if (typeof val !== 'string' && typeof val !== 'object') {
            throw new TypeError('expected string or date object, received ' + typeof val);
        }
        if (typeof val === 'string') {
            return $dayjs(val, 'YYYY-MM-DD', true).isValid() ? $dayjs(val).format('YYYY-MM-DD') : val;
        }
        return $dayjs(val).isValid() ? $dayjs(val).format('YYYY-MM-DD') : val;
    });
};
