function initialState() {
    return {
        completedInitialFetchAfterAuthentication: false,
        tabs: [
            {
                name: 'companySettings',
                isActive: true,
            },
            {
                name: 'administrators',
                isActive: false,
            },
            {
                name: 'groups',
                isActive: false,
            },
            {
                name: 'employees',
                isActive: false,
            },
            {
                name: 'Holidays',
                isActive: false,
            },
            {
                name: 'export',
                isActive: false,
            },
        ],
        // there are 2 endpoints for user fetching.
        // 1. /user - this will give back all detailed information about 20 users at most at once. Offset query param can be applied to fetch more users.
        // 2. /user/list - this is a lightweight user list containing all essential data about every user in the company without limit.
        employeesEssential: [],

        groups: null,
        holidays: null,
        absents: null,
        upcomingEvents: null,
        selectedUser: null,
        daysOff: [],
        dayOffTypes: null,
        dayOffRequests: [],
        unapprovedDayOffRequestsTotalCount: 0,
    };
}

export const state = () => ({
    ...initialState(),
});

export const getters = {
    getTabs(state) {
        return state.tabs;
    },
};

export const mutations = {
    setCompletedInitialFetchAfterAuthentication(state, payload) {
        state.completedInitialFetchAfterAuthentication = payload;
    },
    setActiveTab(state, payload) {
        for (const tab of state.tabs) {
            if (tab.name !== payload) {
                tab.isActive = false;
            }
        }
        state.tabs.find((data) => data.name === payload).isActive = true;
    },

    // Employees list with essential informations
    addEmployeeEssential(state, payload) {
        state.employeesEssential.push(payload);
    },
    setEmployeesEssential(state, payload) {
        state.employeesEssential = payload;
    },
    removeEmployeeEssential(state, payload) {
        state.employeesEssential = [...state.employeesEssential].filter((x) => x.id !== payload);
    },
    updateEmployeeEssential(state, payload) {
        const index = state.employeesEssential.findIndex((d) => {
            return d.id === payload.id;
        });
        state.employeesEssential.splice(index, 1, payload);
    },

    // Days off
    setDaysOff(state, payload) {
        state.daysOff = payload;
    },
    setDayOffTypes(state, payload) {
        state.dayOffTypes = payload;
    },
    addDayOff(state, payload) {
        if (Array.isArray(payload)) {
            payload.forEach((item) => {
                state.daysOff.unshift(item);
            });
        } else {
            state.daysOff.unshift(payload);
        }
    },
    updateDayOff(state, payload) {
        const index = state.daysOff.findIndex((d) => {
            return d.id === payload.id;
        });
        state.daysOff.splice(index, 1, payload);
    },
    removeDayOff(state, payload) {
        state.daysOff = [...state.daysOff].filter((x) => x.id !== payload);
    },

    // Day-off requests
    setUnapprovedDayOffRequests(state, payload) {
        state.dayOffRequests = payload;
    },
    addDayOffRequest(state, payload) {
        if (Array.isArray(payload)) {
            payload.forEach((item) => {
                state.dayOffRequests.unshift(item);
            });
        } else {
            state.dayOffRequests.unshift(payload);
        }
    },
    updateDayOffRequest(state, payload) {
        const index = state.dayOffRequests.findIndex((d) => {
            return d.id === payload.id;
        });
        state.dayOffRequests.splice(index, 1, payload);
    },
    removeDayOffRequest(state, payload) {
        state.dayOffRequests = [...state.dayOffRequests].filter((x) => x.id !== payload);
    },
    setUnapprovedDayOffRequestsTotalCount(state, payload) {
        state.unapprovedDayOffRequestsTotalCount = payload;
    },

    // Groups
    setGroups(state, payload) {
        state.groups = payload;
    },
    addGroup(state, payload) {
        state.groups.push(payload);
    },
    updateGroup(state, payload) {
        const index = state.groups.findIndex((d) => {
            return d.id === payload.id;
        });
        state.groups.splice(index, 1, payload);
    },
    removeGroup(state, payload) {
        state.groups = [...state.groups].filter((x) => x.id !== payload);
    },

    // Non-working days
    setHolidays(state, payload) {
        state.holidays = payload;
    },
    addHoliday(state, payload) {
        state.holidays.push(payload);
    },
    updateHoliday(state, payload) {
        const index = state.holidays.findIndex((d) => {
            return d.id === payload.id;
        });
        state.holidays.splice(index, 1, payload);
    },
    removeHoliday(state, payload) {
        state.holidays = [...state.holidays].filter((x) => x.id !== payload);
    },

    // Absents
    setAbsents(state, payload) {
        state.absents = payload;
    },

    setUpcomingEvents(state, payload) {
        state.upcomingEvents = payload;
    },

    reset(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    },
};

export const actions = {
    fetchEmployeesEssential({ commit }) {
        return this.$axios.$get('/user/list').then((res) => {
            if (res.success === 1) {
                commit('setEmployeesEssential', res.data);
            }
        });
    },

    fetchDaysOff({ commit }) {
        return this.$axios.$get('/day-off').then((res) => {
            if (res.success === 1) {
                commit('setDaysOff', res.data);
                commit('setDayOffTypes', res.types);
            }
        });
    },

    fetchUnapprovedDayOffRequests({ state, commit }) {
        return this.$axios.$get('/day-off/dashboard?limit=-1').then((res) => {
            if (res.success === 1) {
                commit('setUnapprovedDayOffRequests', res.data);
                commit('setUnapprovedDayOffRequestsTotalCount', res.total);
            }
        });
    },

    fetchGroups({ commit }) {
        return this.$axios.$get('/group').then((res) => {
            if (res.success === 1) {
                commit('setGroups', res.data);
            }
        });
    },

    fetchHolidays({ commit }) {
        return this.$axios.$get('/holiday').then((res) => {
            if (res.success === 1) {
                commit('setHolidays', res.data);
            }
        });
    },

    fetchAbsents({ commit }) {
        return this.$axios.$get('/day-off/absent').then((res) => {
            if (res.success === 1) {
                commit('setAbsents', res.data);
            }
        });
    },

    fetchUpcomingEvents({ commit }) {
        return this.$axios.$get('/user/upcoming-events').then((res) => {
            if (res.success === 1) {
                commit('setUpcomingEvents', res.data);
            }
        });
    },
};
