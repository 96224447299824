export default async function ({ store, redirect, localePath, getRouteBaseName }) {
    if (!store.state.auth.token) {
        await store.dispatch('auth/logOut');
        // if (getRouteBaseName().match('register')) {
        //     return;
        // }
        //
        // this.redirect('/login');
    }

    const { user } = store.state.auth;
    const route = getRouteBaseName();

    if (!user && route === 'register') {
        return;
    }

    if (!user && route !== 'register') {
        return redirect(localePath('/register'));
    }

    if (user.selected_company?.has_completed_registration) {
        return redirect(localePath('/dashboard'));
    }

    if (!user.selected_company && route !== 'register-company') {
        return redirect(localePath('/register/company'));
    }

    if (user.selected_company) {
        if (route === 'register' || route === 'register-company') {
            return redirect(localePath('/register/personal-information'));
        }
    }
}
