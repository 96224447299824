export const state = () => ({
    tabs: [
        {
            name: 'personalInformation',
            isActive: true,
        },
        {
            name: 'employmentInformation',
            isActive: false,
        },
        {
            name: 'approvers',
            isActive: false,
        },
        {
            name: 'accountSettings',
            isActive: false,
        },
    ],
});

export const getters = {
    getTabs(state) {
        return state.tabs;
    },
};

export const mutations = {
    setActiveTab(state, payload) {
        for (const tab of state.tabs) {
            if (tab.name !== payload) {
                tab.isActive = false;
            }
        }
        state.tabs.find((data) => data.name === payload).isActive = true;
    },
};
