
export default {
    name: 'Message',
    functional: true,
    props: {
        t: {
            type: String,
            required: false,
            default: '',
        },
        tc: {
            type: String,
            required: false,
            default: '',
        },
        slim: {
            type: Boolean,
            required: false,
            default: false,
        },
        labelClickHandler: {
            type: Function,
            required: true,
        },
    },
    render(createElement, context) {
        const tKey = context.props.tc ? context.props.tc : context.props.t;
        const slotVNode = context.slots().default;

        let isTranslationMissing = false;

        if (!slotVNode[0].text.trim().length) {
            isTranslationMissing = true;
            slotVNode[0].text = tKey;
        }

        if (!context.props.slim) {
            return createElement('span', { class: 'relative' }, [
                slotVNode,
                createElement(
                    'span',
                    {
                        class: `translation-key ${isTranslationMissing ? 'translation-key--missing-value' : ''}`,
                        on: {
                            click: context.props.labelClickHandler,
                        },
                    },
                    tKey
                ),
            ]);
        } else {
            return slotVNode;
        }
    },
};
