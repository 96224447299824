import Vue from 'vue';

Vue.mixin({
    computed: {
        genders() {
            return [
                { name: this.$t('user.male'), id: 1 },
                { name: this.$t('user.female'), id: 2 },
            ];
        },
        roles() {
            return [
                { name: this.$tc('user.role.employee', 1), id: 1 },
                { name: this.$t('user.role.admin'), id: 2 },
                { name: this.$t('user.role.superadmin'), id: 3 },
            ];
        },
        ww() {
            return this.$store.state.globals.windowWidth;
        },
        isTouchUI() {
            return !this.isScreenGtLg && (this.$device.isMobile || this.$device.isTablet);
        },
        computedCalendarParent() {
            return this.isTouchUI ? 'body' : undefined;
        },
        loggedIn() {
            return !!this.$store.state.auth.user;
        },
        $user() {
            return this.$store.state.auth.user;
        },
        isAdmin() {
            return [2, 3].includes(this.$store.state.auth.user.role.id);
        },
        isSuperAdmin() {
            return this.$store.state.auth.user.role.id === 3;
        },
        isHungarianCompany() {
            return this.$store.state.auth.user.selected_company.country_code === 'HU';
        },
        hasFinishedRegistration() {
            return (
                this.$store.state.auth.user.selected_company &&
                this.$store.state.auth.user.selected_company.has_completed_registration
            );
        },
    },
    methods: {
        getFormattedDateInterval(startDate, endDate) {
            if (startDate === endDate) {
                return this.$dayjs(startDate).format('YYYY. MMM DD');
            } else if (this.$dayjs(startDate).month() === this.$dayjs(endDate).month()) {
                return `${this.$dayjs(startDate).format('YYYY. MMM DD')} - ${this.$dayjs(endDate).format('DD')}`;
            } else {
                return `${this.$dayjs(startDate).format('YYYY. MMM. DD')} - ${this.$dayjs(endDate).format('MMM DD')}`;
            }
        },
        showChangesSavedToastMessage(message) {
            if (!message) {
                if (
                    this.getRouteBaseName()?.match('/^(user)/') &&
                    (!this.$route.params.id || this.$route.params.id === this.$store.state.auth.user.id)
                ) {
                    message = this.$t('generic.data_modified');
                } else {
                    message = this.$t('generic.data_modified');
                }
            }
            this.$toast.add({
                summary: {
                    icon: require('~/assets/images/icons/settings.svg'),
                    message,
                },
                life: 4000,
            });
        },
        showDayOffNotFoundErrorToastMessage() {
            this.$store.dispatch('company/fetchDaysOff');

            this.$toast.add({
                summary: {
                    icon: require('~/assets/images/icons/denied.svg'),
                    message: this.$t('day_off.already_canceled'),
                },
                life: 4000,
            });
        },
        getDaysOffPayload(daysOff) {
            const payload = {
                used: daysOff.used,
                used_sick: daysOff.used_sick,
                custom: daysOff.custom.length ? daysOff.custom : '',
                deducted: daysOff.deducted.length ? daysOff.deducted : '',
            };
            if (this.$store.state.auth.user.selected_company.country_code !== 'HU') {
                payload.base = daysOff.base;
                payload.base_sick = daysOff.base_sick;
            }
            return payload;
        },
        getUserHasSpecialWorkSchedule(workHours) {
            if (!workHours) {
                return false;
            }
            return !!Object.keys(workHours).find(
                (day) => this.$store.state.auth.user.selected_company[day] !== workHours[day]
            );
        },
        d(message) {
            if (this.$store.state.globals.showDebugMessages) {
                console.log(message);
            }
        },
        getLocalizedUserName(firstName, lastName) {
            if (this.$i18n.locale === 'hu') {
                return `${lastName} ${firstName}`;
            }
            return `${firstName} ${lastName}`;
        },
    },
});
