export const state = () => ({
    host: null,
});

export const mutations = {
    setHost(state, payload) {
        state.host = payload;
    },
};

export const actions = {
    async fetchEssentialData({ state, dispatch }, omitFetchingUser = false) {
        // we do these separately in order to only make one call to refresh token if needed
        if (!omitFetchingUser) {
            await dispatch('auth/fetchUser');
        }

        const userPreferredLanguage = state.auth.user.preferences.language;

        if (userPreferredLanguage !== this.$i18n.locale) {
            await this.$i18n.setLocale(userPreferredLanguage);
        }
    },
    async nuxtServerInit({ dispatch, commit, state }, { req }) {
        commit('setHost', req.headers.host);

        try {
            if (!state.auth.token) {
                return;
            }

            await dispatch('fetchEssentialData');
        } catch (err) {
            console.log(err);
        }
    },
};
