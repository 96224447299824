//
//
//
//
//
//
//
//
//
//
//

import MelkwegLogo from '../../Logos/MelkwegLogo';
import CreatedByMelkweg from '../../Generic/CreatedByMelkweg';

export default {
    components: {
        MelkwegLogo,
        CreatedByMelkweg,
    },
};
