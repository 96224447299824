export default async function ({ store, redirect, localePath, getRouteBaseName }) {
    if (!store.state.auth.token || !store.state.auth.user) {
        await store.dispatch('auth/logOut');
        if (!getRouteBaseName().match('register')) {
            redirect('/login');
        }
    }

    const { user } = store.state.auth;

    if (!user?.selected_company?.has_completed_registration) {
        return redirect(localePath('/register'));
    }
}
