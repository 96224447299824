//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '~/mixins/i18n';
import ToastMessage from '~/components/Generic/ToastMessage';
import LoggedInHeader from '~/components/Layout/Headers/LoggedInHeader';
import DefaultHeader from '~/components/Layout/Headers/DefaultHeader';
import LoggedOutFooter from '~/components/Layout/Footers/LoggedOutFooter';
import GridOverlay from '~/components/Layout/GridOverlay';

export default {
    name: 'DefaultLayout',
    components: {
        DefaultHeader,
        LoggedInHeader,
        LoggedOutFooter,
        GridOverlay,
        ToastMessage,
    },
    mixins: [i18n],
    middleware: ['redirect-guest'],
};
