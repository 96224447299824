//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '~/mixins/i18n';
import ToastMessage from '~/components/Generic/ToastMessage';
import LoggedInHeader from '~/components/Layout/Headers/LoggedInHeader';
import LoggedInFooter from '~/components/Layout/Footers/LoggedInFooter';
import GridOverlay from '~/components/Layout/GridOverlay';

export default {
    name: 'AuthenticatedLayout',
    components: {
        LoggedInHeader,
        LoggedInFooter,
        GridOverlay,
        ToastMessage,
    },
    mixins: [i18n],
    middleware: ['redirect-user', 'init-authenticated'],
};
