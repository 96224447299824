//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoggedOutLogo from '@/components/Logos/LoggedOutLogo';
import NavLink from '@/components/Generic/NavLink';
import CreatedByMelkweg from '@/components/Generic/CreatedByMelkweg';

export default {
    components: {
        LoggedOutLogo,
        NavLink,
        CreatedByMelkweg,
    },
    data() {
        return {
            env: process.env.NODE_ENV,
        };
    },
    methods: {
        handleLogoClick(e) {
            if (this.getRouteBaseName() === 'index') {
                e.preventDefault();
                window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            }
        },
    },
};
