import jwtDecode from 'jwt-decode';

export const state = () => ({
    rememberMe: true,
    token: null,
    user: null,
});

export const mutations = {
    setUser(state, payload) {
        state.user = payload;
    },
    updateUser(state, payload) {
        Object.keys(payload).forEach((key) => {
            state.user[key] = payload[key];
        });
    },
    setToken(state, payload) {
        state.token = payload;
    },
    setRememberMe(state, payload) {
        state.rememberMe = payload;
    },
    reset(state) {
        state.user = null;
        state.token = null;
    },
};

export const actions = {
    logIn({ commit, dispatch }, payload) {
        if (!payload.user || !payload.token) {
            throw new Error('user or token is missing');
        }

        commit('setUser', payload.user);
        commit('setToken', payload.token);
        dispatch('fetchEssentialData', true, { root: true });
    },
    logOut({ commit, state }) {
        if (!state.user) {
            return;
        }

        return this.$axios.$post('/auth/logout').then(() => {
            commit('reset');
            commit('company/reset', null, { root: true });
        });
    },
    fetchUser({ commit }) {
        return this.$axios.$get('/user/me').then((res) => {
            if (res.success === 1) {
                commit('setUser', res.data);
            }
        });
    },
    async refreshToken({ commit, state }, url) {
        let decodedAccessToken;

        if (state.token) {
            decodedAccessToken = jwtDecode(state.token);
        }

        // refresh token 5 seconds before token is expired, just to make it sure
        // console.log('****************************************');
        if (!decodedAccessToken || Date.now() >= (decodedAccessToken.exp - 5) * 1000) {
            return await this.$axios
                .$post('/auth/refresh-token')
                .then((res) => {
                    if (res.success === 1) {
                        commit('setToken', res.token);
                    } else {
                        commit('setToken', '');
                    }
                })
                .catch((err) => {
                    commit('reset');
                    commit('company/reset', null, { root: true });
                    console.log(err.message);
                });
        }

        // if refreshing the token is not needed for the given request, just return an empty promise
        return new Promise((resolve) => {
            resolve(null);
        });
    },
};
