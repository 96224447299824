//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { throttle } from 'throttle-debounce';
import Dropdown from 'primevue/dropdown';
import NavLink from '../../Generic/NavLink';
import CreatedByMelkweg from '../../Generic/CreatedByMelkweg';
import LoggedOutLogo from '../../Logos/LoggedOutLogo';
import MelkwegLogo from '../../Logos/MelkwegLogo';

export default {
    components: {
        LoggedOutLogo,
        NavLink,
        Dropdown,
        MelkwegLogo,
        CreatedByMelkweg,
    },
    removeMenuHasTransitionTimeout: null,
    data() {
        const defaultSelectedLanguage = this.$i18n.locales.filter((i) => i.code === this.$i18n.locale)[0];

        return {
            selectedLanguage: defaultSelectedLanguage,
            isMobileMenuOpen: false,
            showFullMenu: !this.getRouteBaseName() || this.getRouteBaseName().match(/index|help|privacy-policy/),

            menuHasTransition: false,
            prevWindowWidth: null,
            resizeHandler: throttle(100, this.handleResize),
        };
    },
    computed: {
        routeBaseName() {
            return this.getRouteBaseName();
        },
        isLoginPage() {
            if (!this.getRouteBaseName()) {
                return false;
            }
            return this.getRouteBaseName() === 'login';
        },
        registerButtonText() {
            // if (this.isScreenGtLg && this.ww < 1080) {
            //     return this.$t('core.register');
            // }
            return this.$t('core.register_company');
        },
    },
    watch: {
        routeBaseName() {
            if (!this.routeBaseName) {
                this.showFullMenu = true;
            }
            this.showFullMenu = !!this.routeBaseName.match(/index|help|privacy-policy/);
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandler);
    },
    methods: {
        handleResize() {
            if (window.innerWidth >= 1024 && this.prevWindowWidth < 1024) {
                this.isMobileMenuOpen = false;
                this.menuHasTransition = false;
            }
            this.$refs.lngSelector.hide();
            this.prevWindowWidth = window.innerWidth;
        },
        handleLanguageChange(e) {
            setTimeout(() => {
                this.$i18n.setLocale(e.value.code);
            }, 50);
        },
        toggleMobileMenu() {
            if (window.innerWidth < 1024) {
                this.isMobileMenuOpen = !this.isMobileMenuOpen;
                if (this.isMobileMenuOpen) {
                    clearTimeout(this.removeMenuHasTransitionTimeout);
                    this.menuHasTransition = true;
                } else {
                    this.removeMenuHasTransitionTimeout = setTimeout(() => {
                        this.menuHasTransition = false;
                    }, 500);
                }
            }
        },
    },
};
