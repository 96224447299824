import createPersistedState from 'vuex-persistedstate';

// Store paths defined here will be saved in cookies, persisted data will be visible on both client and server-side.
// Keep in mind that the combined size of the cookies can not exceed 4Kb.
// If it does but client only storage is fine, persist your data in persisted-state.client.js instead.
// More info:  https://www.npmjs.com/package/vuex-persistedstate

export default ({ store, $cookies }) => {
    createPersistedState({
        key: 'holi__vuex_data',
        paths: ['register.user', 'user.tabs', 'company.tabs', 'preference'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value);
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);

    createPersistedState({
        key: 'holi__remember_me',
        paths: ['auth.rememberMe'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 365 * 2,
                });
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);

    createPersistedState({
        key: 'holi__token',
        paths: ['auth.token'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value, {
                    path: '/',
                    maxAge: store.state.auth.rememberMe ? 60 * 60 * 24 * 30.44 * 6 : 60 * 60,
                });
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);
};
