import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _39154b74 = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _e13bc040 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _1098c05c = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _0ab6a9e1 = () => interopDefault(import('../pages/days-off.vue' /* webpackChunkName: "pages/days-off" */))
const _1859c79a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d8f97966 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _3e8dee29 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _3bfd2a9e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a986181c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _d84a3a02 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _3b8396ce = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _20377a80 = () => interopDefault(import('../pages/company/administrators.vue' /* webpackChunkName: "pages/company/administrators" */))
const _50ea528a = () => interopDefault(import('../pages/company/employees.vue' /* webpackChunkName: "pages/company/employees" */))
const _287ed1ee = () => interopDefault(import('../pages/company/export.vue' /* webpackChunkName: "pages/company/export" */))
const _6983880e = () => interopDefault(import('../pages/company/groups.vue' /* webpackChunkName: "pages/company/groups" */))
const _24eadea6 = () => interopDefault(import('../pages/company/non-working-days.vue' /* webpackChunkName: "pages/company/non-working-days" */))
const _d4146b46 = () => interopDefault(import('../pages/company/settings.vue' /* webpackChunkName: "pages/company/settings" */))
const _398c53f9 = () => interopDefault(import('../pages/register/company.vue' /* webpackChunkName: "pages/register/company" */))
const _f9c3e15a = () => interopDefault(import('../pages/register/personal-information.vue' /* webpackChunkName: "pages/register/personal-information" */))
const _d09c24de = () => interopDefault(import('../pages/register/team.vue' /* webpackChunkName: "pages/register/team" */))
const _65ff8c62 = () => interopDefault(import('../pages/user/account-settings.vue' /* webpackChunkName: "pages/user/account-settings" */))
const _7a11211c = () => interopDefault(import('../pages/user/approvers.vue' /* webpackChunkName: "pages/user/approvers" */))
const _4d241a05 = () => interopDefault(import('../pages/user/days-off.vue' /* webpackChunkName: "pages/user/days-off" */))
const _7001f640 = () => interopDefault(import('../pages/user/export.vue' /* webpackChunkName: "pages/user/export" */))
const _c4ddf1aa = () => interopDefault(import('../pages/user/personal-information.vue' /* webpackChunkName: "pages/user/personal-information" */))
const _3c121c44 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _75487904 = () => interopDefault(import('../pages/user/_id/account-settings.vue' /* webpackChunkName: "pages/user/_id/account-settings" */))
const _05029c46 = () => interopDefault(import('../pages/user/_id/approvers.vue' /* webpackChunkName: "pages/user/_id/approvers" */))
const _46c55e3a = () => interopDefault(import('../pages/user/_id/days-off.vue' /* webpackChunkName: "pages/user/_id/days-off" */))
const _7620c3b5 = () => interopDefault(import('../pages/user/_id/export.vue' /* webpackChunkName: "pages/user/_id/export" */))
const _5c8ca3e0 = () => interopDefault(import('../pages/user/_id/personal-information.vue' /* webpackChunkName: "pages/user/_id/personal-information" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accept-invitation",
    component: _39154b74,
    name: "accept-invitation___hu___default"
  }, {
    path: "/change-password",
    component: _e13bc040,
    name: "change-password___hu___default"
  }, {
    path: "/dashboard",
    component: _1098c05c,
    name: "dashboard___hu___default"
  }, {
    path: "/days-off",
    component: _0ab6a9e1,
    name: "days-off___hu___default"
  }, {
    path: "/en",
    component: _1859c79a,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _d8f97966,
    name: "forgot-password___hu___default"
  }, {
    path: "/help",
    component: _3e8dee29,
    name: "help___hu___default"
  }, {
    path: "/hu",
    component: _1859c79a,
    name: "index___hu"
  }, {
    path: "/login",
    component: _3bfd2a9e,
    name: "login___hu___default"
  }, {
    path: "/logout",
    component: _a986181c,
    name: "logout___hu___default"
  }, {
    path: "/privacy-policy",
    component: _d84a3a02,
    name: "privacy-policy___hu___default"
  }, {
    path: "/register",
    component: _3b8396ce,
    name: "register___hu___default"
  }, {
    path: "/company/administrators",
    component: _20377a80,
    name: "company-administrators___hu___default"
  }, {
    path: "/company/employees",
    component: _50ea528a,
    name: "company-employees___hu___default"
  }, {
    path: "/company/export",
    component: _287ed1ee,
    name: "company-export___hu___default"
  }, {
    path: "/company/groups",
    component: _6983880e,
    name: "company-groups___hu___default"
  }, {
    path: "/company/non-working-days",
    component: _24eadea6,
    name: "company-non-working-days___hu___default"
  }, {
    path: "/company/settings",
    component: _d4146b46,
    name: "company-settings___hu___default"
  }, {
    path: "/en/accept-invitation",
    component: _39154b74,
    name: "accept-invitation___en"
  }, {
    path: "/en/change-password",
    component: _e13bc040,
    name: "change-password___en"
  }, {
    path: "/en/dashboard",
    component: _1098c05c,
    name: "dashboard___en"
  }, {
    path: "/en/days-off",
    component: _0ab6a9e1,
    name: "days-off___en"
  }, {
    path: "/en/forgot-password",
    component: _d8f97966,
    name: "forgot-password___en"
  }, {
    path: "/en/help",
    component: _3e8dee29,
    name: "help___en"
  }, {
    path: "/en/login",
    component: _3bfd2a9e,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _a986181c,
    name: "logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _d84a3a02,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _3b8396ce,
    name: "register___en"
  }, {
    path: "/hu/accept-invitation",
    component: _39154b74,
    name: "accept-invitation___hu"
  }, {
    path: "/hu/change-password",
    component: _e13bc040,
    name: "change-password___hu"
  }, {
    path: "/hu/dashboard",
    component: _1098c05c,
    name: "dashboard___hu"
  }, {
    path: "/hu/days-off",
    component: _0ab6a9e1,
    name: "days-off___hu"
  }, {
    path: "/hu/forgot-password",
    component: _d8f97966,
    name: "forgot-password___hu"
  }, {
    path: "/hu/help",
    component: _3e8dee29,
    name: "help___hu"
  }, {
    path: "/hu/login",
    component: _3bfd2a9e,
    name: "login___hu"
  }, {
    path: "/hu/logout",
    component: _a986181c,
    name: "logout___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _d84a3a02,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/register",
    component: _3b8396ce,
    name: "register___hu"
  }, {
    path: "/register/company",
    component: _398c53f9,
    name: "register-company___hu___default"
  }, {
    path: "/register/personal-information",
    component: _f9c3e15a,
    name: "register-personal-information___hu___default"
  }, {
    path: "/register/team",
    component: _d09c24de,
    name: "register-team___hu___default"
  }, {
    path: "/user/account-settings",
    component: _65ff8c62,
    name: "user-account-settings___hu___default"
  }, {
    path: "/user/approvers",
    component: _7a11211c,
    name: "user-approvers___hu___default"
  }, {
    path: "/user/days-off",
    component: _4d241a05,
    name: "user-days-off___hu___default"
  }, {
    path: "/user/export",
    component: _7001f640,
    name: "user-export___hu___default"
  }, {
    path: "/user/personal-information",
    component: _c4ddf1aa,
    name: "user-personal-information___hu___default"
  }, {
    path: "/en/company/administrators",
    component: _20377a80,
    name: "company-administrators___en"
  }, {
    path: "/en/company/employees",
    component: _50ea528a,
    name: "company-employees___en"
  }, {
    path: "/en/company/export",
    component: _287ed1ee,
    name: "company-export___en"
  }, {
    path: "/en/company/groups",
    component: _6983880e,
    name: "company-groups___en"
  }, {
    path: "/en/company/non-working-days",
    component: _24eadea6,
    name: "company-non-working-days___en"
  }, {
    path: "/en/company/settings",
    component: _d4146b46,
    name: "company-settings___en"
  }, {
    path: "/en/register/company",
    component: _398c53f9,
    name: "register-company___en"
  }, {
    path: "/en/register/personal-information",
    component: _f9c3e15a,
    name: "register-personal-information___en"
  }, {
    path: "/en/register/team",
    component: _d09c24de,
    name: "register-team___en"
  }, {
    path: "/en/user/account-settings",
    component: _65ff8c62,
    name: "user-account-settings___en"
  }, {
    path: "/en/user/approvers",
    component: _7a11211c,
    name: "user-approvers___en"
  }, {
    path: "/en/user/days-off",
    component: _4d241a05,
    name: "user-days-off___en"
  }, {
    path: "/en/user/export",
    component: _7001f640,
    name: "user-export___en"
  }, {
    path: "/en/user/personal-information",
    component: _c4ddf1aa,
    name: "user-personal-information___en"
  }, {
    path: "/hu/company/administrators",
    component: _20377a80,
    name: "company-administrators___hu"
  }, {
    path: "/hu/company/employees",
    component: _50ea528a,
    name: "company-employees___hu"
  }, {
    path: "/hu/company/export",
    component: _287ed1ee,
    name: "company-export___hu"
  }, {
    path: "/hu/company/groups",
    component: _6983880e,
    name: "company-groups___hu"
  }, {
    path: "/hu/company/non-working-days",
    component: _24eadea6,
    name: "company-non-working-days___hu"
  }, {
    path: "/hu/company/settings",
    component: _d4146b46,
    name: "company-settings___hu"
  }, {
    path: "/hu/register/company",
    component: _398c53f9,
    name: "register-company___hu"
  }, {
    path: "/hu/register/personal-information",
    component: _f9c3e15a,
    name: "register-personal-information___hu"
  }, {
    path: "/hu/register/team",
    component: _d09c24de,
    name: "register-team___hu"
  }, {
    path: "/hu/user/account-settings",
    component: _65ff8c62,
    name: "user-account-settings___hu"
  }, {
    path: "/hu/user/approvers",
    component: _7a11211c,
    name: "user-approvers___hu"
  }, {
    path: "/hu/user/days-off",
    component: _4d241a05,
    name: "user-days-off___hu"
  }, {
    path: "/hu/user/export",
    component: _7001f640,
    name: "user-export___hu"
  }, {
    path: "/hu/user/personal-information",
    component: _c4ddf1aa,
    name: "user-personal-information___hu"
  }, {
    path: "/en/user/:id?",
    component: _3c121c44,
    name: "user-id___en",
    children: [{
      path: "account-settings",
      component: _75487904,
      name: "user-id-account-settings___en"
    }, {
      path: "approvers",
      component: _05029c46,
      name: "user-id-approvers___en"
    }, {
      path: "days-off",
      component: _46c55e3a,
      name: "user-id-days-off___en"
    }, {
      path: "export",
      component: _7620c3b5,
      name: "user-id-export___en"
    }, {
      path: "personal-information",
      component: _5c8ca3e0,
      name: "user-id-personal-information___en"
    }]
  }, {
    path: "/hu/user/:id?",
    component: _3c121c44,
    name: "user-id___hu",
    children: [{
      path: "account-settings",
      component: _75487904,
      name: "user-id-account-settings___hu"
    }, {
      path: "approvers",
      component: _05029c46,
      name: "user-id-approvers___hu"
    }, {
      path: "days-off",
      component: _46c55e3a,
      name: "user-id-days-off___hu"
    }, {
      path: "export",
      component: _7620c3b5,
      name: "user-id-export___hu"
    }, {
      path: "personal-information",
      component: _5c8ca3e0,
      name: "user-id-personal-information___hu"
    }]
  }, {
    path: "/user/:id?",
    component: _3c121c44,
    name: "user-id___hu___default",
    children: [{
      path: "account-settings",
      component: _75487904,
      name: "user-id-account-settings___hu___default"
    }, {
      path: "approvers",
      component: _05029c46,
      name: "user-id-approvers___hu___default"
    }, {
      path: "days-off",
      component: _46c55e3a,
      name: "user-id-days-off___hu___default"
    }, {
      path: "export",
      component: _7620c3b5,
      name: "user-id-export___hu___default"
    }, {
      path: "personal-information",
      component: _5c8ca3e0,
      name: "user-id-personal-information___hu___default"
    }]
  }, {
    path: "/",
    component: _1859c79a,
    name: "index___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
