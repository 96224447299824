import Vue from 'vue';

Vue.mixin({
    computed: {
        isScreenGtLg() {
            return this.$store.state.globals.windowWidth >= 1024;
        },
        isScreenGtMd() {
            return this.$store.state.globals.windowWidth >= 768;
        },
    },
});
