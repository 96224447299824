//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        fillColor: {
            type: String,
            required: false,
            default: '#000',
        },
    },
};
