//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'UnpopulatedSectionPlaceholder',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        buttonLabel: {
            type: String,
            required: false,
            default: null,
        },
        buttonClickHandler: {
            type: Function,
            required: false,
            default: null,
        },
    },
};
