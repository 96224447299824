import Vue from 'vue';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Steps from 'primevue/steps';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Password from 'primevue/password';
import Textarea from 'primevue/textarea';
import Carousel from 'primevue/carousel';
import AutoComplete from 'primevue/autocomplete';
import Skeleton from 'primevue/skeleton/Skeleton';

Vue.use(ToastService, {
    severity: 'info',
    life: 4000,
});

Vue.use(PrimeVue, {
    locales: {
        en: {
            firstDayOfWeek: 1,
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'mm/dd/yy',
            weekHeader: 'Wk',
        },
        hu: {
            firstDayOfWeek: 1,
            dayNames: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
            dayNamesShort: ['Vas', 'Hé', 'Ke', 'Sze', 'Csü', 'Pé', 'Szo'],
            dayNamesMin: ['Vas', 'Hé', 'Ke', 'Sze', 'Csü', 'Pé', 'Szo'],
            monthNames: [
                'Január',
                'Február',
                'Március',
                'Április',
                'Május',
                'Június',
                'Július',
                'Augusztus',
                'Szeptember',
                'Október',
                'November',
                'December',
            ],
            monthNamesShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec'],
            today: 'Ma',
            clear: 'Törlés',
            dateFormat: 'nn/hh/éééé',
            weekHeader: 'Hét',
        },
    },
});

Vue.directive('Tooltip', Tooltip);
Vue.component('Calendar', Calendar);
Vue.component('Textarea', Textarea);
Vue.component('Steps', Steps);
Vue.component('Dropdown', Dropdown);
Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('Checkbox', Checkbox);
Vue.component('RadioButton', RadioButton);
Vue.component('Password', Password);
Vue.component('Button', Button);
Vue.component('Carousel', Carousel);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Skeleton', Skeleton);
