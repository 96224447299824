export const state = () => ({
    user: null,
    steps: ['Registration', 'Company info', 'Your data', 'Team set-up'],
    isUserDataFormLayerOpen: false,
    currentlyEditedTeamMember: null,
    showRegSuccessMessageWhenOpeningDashboard: false,
});

export const mutations = {
    setCurrentlyEditedTeamMember(state, payload) {
        state.currentlyEditedTeamMember = payload;
    },

    setIsUserDataFormLayerOpen(state, payload) {
        state.isUserDataFormLayerOpen = payload;
    },
    setShowRegSuccessMessageWhenOpeningDashboard(state, payload) {
        state.showRegSuccessMessageWhenOpeningDashboard = payload;
    },
};
