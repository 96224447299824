//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Toast from 'primevue/toast';
import ToastMessageContent from './ToastMessageContent';

export default {
    name: 'ToastMessage',
    components: {
        Toast,
        ToastMessageContent,
    },
};
