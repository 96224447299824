//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayOffRequest from '../../../mixins/dayOffRequest';

export default {
    name: 'NotificationDropdownItem',
    mixins: [dayOffRequest],
    inject: ['removeNotification'],
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isVisible: true,
        };
    },
    computed: {
        localizedPath() {
            return this.localePath('/days-off');
        },
        dayOffMonth() {
            return this.notification;
        },
        notificationData() {
            return this.getNotificationContent(this.notification);
        },
    },
    methods: {
        deleteNotification() {
            this.$axios
                .$delete('/notification/' + this.notification.id)
                .then((res) => {
                    if (res.success === 1) {
                        this.isVisible = false;

                        setTimeout(() => {
                            this.removeNotification(this.notification.id);
                        }, 300);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
